<template>
  <div>
    <Loading :center="false" v-show="loading" />
    <div v-show="!hasCollaborator && !loading">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="7">
          <Alert type="warning">
            <span>
              É necessário Vincular o Usuário ao Consultor! Acesse Recursos
              Humanos, e selecione a opção Usuário Vinculado! Caso não tenha
              acesso solicite ao administrador do Sistema.
            </span>
          </Alert>
        </b-col>
      </b-row>
    </div>
    <div class="div-collaborator" v-show="hasCollaborator && !loading">
      <CollaboratorWithUser :formName="formName" :disabled="false" v-model="rent.collaborator" />
    </div>
    <div v-show="hasCollaborator && !loading">
      <Molded>
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="3">
            <InputText title="Identificação" field="name" :formName="formName" :required="false" :maxLength="100"
              v-model="rent.identification" />
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="6">
            <div>
              <CustomerWithCreate :formName="formName" v-model="rent.customer" />
            </div>
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="3">
            <Select title="Contato" field="type" :formName="formName" :required="true" :disabled="!rent.customer.id"
              url="/api/v1/shared/contact/select-all" :propsParams="{ any: rent.customer.id }" v-model="rent.contact"
              nameNewRegister="contactCreateUpdate" :showNewRegister="hasRule('CRM')" titleNewRegister="Contato"
              :widthNewRegister="750" :heightNewRegister="250">
              <ContactCreateUpdate :registerInSelect="true" :customerSupplierId="rent.customer.id"
                v-model="rent.contact" />
            </Select>
          </b-col>
        </b-row>
      </Molded>
      <br />
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="6">
          <Molded>
            <div>Período de Locação</div>
            <div class="div-btn-period text-center" v-if="!hasPeriodRent">
              <Button _key="btnAddPeriodRent" title="Periodo de Locação" classIcon="fas fa-plus-circle" type="primary"
                size="small" :clicked="openPeriodRent" />
              <div class="line"></div>
            </div>
            <PeriodRentItem v-else />
            <div class="text-center sidey btn-edit-cancel" v-if="hasPeriodRent">
              <Button _key="btnEditPeriodRent" type="edit" title="Editar" classIcon="fa-solid fa-pen-to-square"
                size="small" :clicked="editPeriodRent" />
              <Button _key="btnCancelPeriodRent" type="danger" classIcon="fa-solid fa-circle-xmark" size="small"
                :clicked="cleanPeriodRent" />
            </div>
          </Molded>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="6">
          <Molded>
            <SearchByBarcode :params="paramsSearch" :disabled="!hasPeriodRent" />
            <SearchManual :disabled="!hasPeriodRent" :periodRent="rent.periodRent" />
          </Molded>
        </b-col>
      </b-row>
      <div class="div-tab">
        <b-tabs>
          <b-tab title="Produtos">
            <Loading type="line" :center="false" v-show="loadingCalculateTotalization" />
            <ItemsRentList />
          </b-tab>
          <b-tab title="Saída/Retorno">
            <AddressRentCreateUpdate module="PDV" :rent="rent" />
            <AddressRentList />
          </b-tab>
          <b-tab title="Observações">
            <b-row>
              <b-col sm="12">
                <EditorHtml title="Observações da Locação" field="observationRental" :required="false" :maxLength="3000"
                  v-model="rent.observationRental" />
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12">
                <EditorHtml title="Observações da OS" field="observationServiceOrder" :required="false"
                  :maxLength="3000" v-model="rent.observationServiceOrder" />
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </div>
      <br />
      <br />
      <FixedBar position="footer" backgroundColor="#FAFAFC">
        <b-row>
          <b-col sm="8">
            <DisplayTotalization title="Sub-Total" :value="totalization.totalValue" />
            <DisplayTotalization v-if="totalization.totalDiscount > 0" title="Desconto"
              :value="totalization.totalDiscount" _style="color: red" />
            <DisplayTotalization v-if="totalization.totalInsurance > 0" title="Seguro"
              :value="totalization.totalInsurance" _style="color: #808080" />
            <DisplayTotalization title="TOTAL GERAL" :value="totalization.totalGeneral"
              _style="color: #1b3a55;font-size:19px" />
          </b-col>
          <b-col sm="4">
            <div class="div-btn-end text-center">
              <button class="btn-finalize" :class="{
      'btn-finalize-disabled': !isFormValid,
    }" :disabled="!isFormValid || loadingFinalize" @click="finalizeRent()">
                <span v-if="!loadingFinalize"> Finalizar</span>
                <vue-loading v-else type="bubbles" color="#fff" :size="{ width: '46px', height: '46px' }"></vue-loading>
              </button>
            </div>
          </b-col>
        </b-row>
      </FixedBar>
    </div>
    <Modal title="Período de Locação" :width="900" :height="750" v-show="showModal('periodRentUpdate')">
      <PeriodRentCreateUpdate />
    </Modal>
    <Modal title="Período de Locação" :width="900" :height="750" v-show="showModal('addPeriodRent')">
      <GeneratePeriodRent :isShowStartDateHour="true" v-model="rent.periodRent" />
      <b-row>
        <b-col sm="12">
          <div class="text-right">
            <Button _key="savePeriodRent" type="success" title="Salvar" classIcon="fas fa-save" size="medium"
              :disabled="!hasPeriodRent" :clicked="savePeriodRent" />
          </div>
        </b-col>
      </b-row>
    </Modal>
    <Modal title="Finalizar" :width="900" :height="750" v-if="showModal('finalizeRent')">
      <FinalizeRent />
    </Modal>
    <Modal title="Abrir Caixa" :width="600" :height="750" :closeOnEsc="false" :closeButton="false"
      v-if="showModal('openCashier')">
      <OpenCashier :userLogged="userLogged" urlCreate="/api/v1/pdv/open-cashier" />
    </Modal>
    <Modal title="Aviso(s)" :width="900" :height="750" v-show="showModal('listError')">
      <ListNotifications :listError="listError" />
    </Modal>
  </div>
</template>

<script>
import AddressRentList from "./AddressRentList.vue";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button.vue";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import FixedBar from "@nixweb/nixloc-ui/src/component/layout/FixedBar.vue";
import DisplayTotalization from "@nixweb/nixloc-ui/src/component/rental/DisplayTotalization";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import EditorHtml from "@nixweb/nixloc-ui/src/component/forms/EditorHtml";
import ListNotifications from "@nixweb/nixloc-ui/src/component/shared/ListNotifications.vue";

import AddressRentCreateUpdate from "../../rental/address-rent/AddressRentCreateUpdate.vue";
import CustomerWithCreate from "../../rental/shared/CustomerWithCreate.vue";
import ContactCreateUpdate from "../../shared/contact/ContactCreateUpdate.vue";
import GeneratePeriodRent from "../../rental/period-rental/GeneratePeriodRent.vue";
import FinalizeRent from "./FinalizeRent.vue";

import OpenCashier from "../../cashier/OpenCashier.vue";

import ItemsRentList from "./ItemsRentList.vue";
import PeriodRentItem from "../../rental/period-rental/PeriodRentItem.vue";
import OpticalReaderCreate from "./SearchByBarcode.vue";
import CollaboratorWithUser from "../../rental/shared/CollaboratorWithUser.vue";
import PeriodRentCreateUpdate from "../../rental/period-rental/PeriodRentCreateUpdate.vue";
import SearchManual from "./SearchManual.vue";
import SearchByBarcode from "./SearchByBarcode.vue";

import Rent from "@/components/modules/pdv/rent/Rent.js";

import { mapGetters, mapMutations, mapState, mapActions } from "vuex";

export default {
  name: "PdvRentCreate",
  components: {
    InputText,
    Molded,
    Button,
    ScrollBar,
    Select,
    FixedBar,
    Alert,
    Loading,
    Modal,
    EditorHtml,
    DisplayTotalization,
    CustomerWithCreate,
    ContactCreateUpdate,
    GeneratePeriodRent,
    CollaboratorWithUser,
    PeriodRentItem,
    ItemsRentList,
    OpticalReaderCreate,
    SearchByBarcode,
    SearchManual,
    PeriodRentCreateUpdate,
    ListNotifications,
    FinalizeRent,
    OpenCashier,
    AddressRentCreateUpdate,
    AddressRentList,
  },
  data() {
    return {
      formName: "pdvRentCreate",
      rent: new Rent(),
      urlHasOpenCashier: "/api/v1/cashier/has-open-cashier",
      urlTotalization: "/api/v1/pdv/totalization",
      urlValidate: "/api/v1/pdv/validate",
      totalization: {},
      loading: true,
      loadingCalculateTotalization: false,
      listError: [],
      loadingFinalize: false,
    };
  },
  mounted() {
    if (this.periodRent.deliveryDateHour)
      this.rent.periodRent = this.periodRent;

    this.addEvent({ name: "generatePeriodRent", data: this.periodRent });

    let self = this;
    setTimeout(function () {
      self.loading = false;
      if (self.hasCollaborator) {
        self.hasOpenCashier();
      }
    }, 500);
  },
  beforeDestroy() {
    this.resetRent();
    this.resetPayment();
  },
  computed: {
    ...mapState("user", ["userLogged"]),
    ...mapGetters("user", ["hasRule"]),
    ...mapState("pdv", ["periodRent", "itemsRent"]),
    ...mapGetters("generic", ["showModal", "event"]),
    hasCollaborator() {
      if (this.rent.collaborator.id) return true;
      return false;
    },
    hasPeriodRent() {
      if (this.rent.periodRent.deliveryDateHour) return true;
      return false;
    },
    paramsSearch() {
      let obj = {
        statusPatrimony: 1,
        searchRequest: {
          types: "1,2,3,0",
          status: 1,
          statusPatrimony: 1,
          showPrice: true,
          showAvailability: false,
          deliveryDateHour: this.rent.periodRent.deliveryDateHour,
          startDateHour: this.rent.periodRent.startDateHour,
          endDateHour: this.rent.periodRent.endDateHour,
          devolutionDateHour: this.rent.periodRent.devolutionDateHour,
          minute: this.rent.periodRent.minute,
          hour: this.rent.periodRent.hour,
          day: this.rent.periodRent.day,
          month: this.rent.periodRent.month,
          year: this.rent.periodRent.year,
        },
      };
      return obj;
    },
    isFormValid() {
      if (
        !this.rent.collaborator.id ||
        !this.rent.customer.id ||
        !this.rent.contact.id ||
        !this.rent.periodRent.deliveryDateHour ||
        this.itemsRent.length == 0
      )
        return false;
      return true;
    },
  },
  methods: {
    ...mapMutations("generic", [
      "addEvent",
      "openModal",
      "hideModal",
      "removeLoading",
    ]),
    ...mapMutations("pdv", [
      "addPeriodRent",
      "updateRent",
      "addTotalization",
      "updateItemsRent",
      "updateCashier",
      "resetRent",
    ]),
    ...mapMutations("paymentShared", ["resetPayment"]),
    ...mapActions("generic", ["postApi", "getApi"]),
    hasOpenCashier() {
      let params = {
        url: this.urlHasOpenCashier,
        obj: { userId: this.userLogged.user.id },
      };
      this.getApi(params).then((response) => {
        if (!response.content) {
          this.openModal("openCashier");
        } else {
          this.updateCashier(response.content);
        }
      });
    },
    calculateTotalization() {
      this.loadingCalculateTotalization = true;
      let params = {
        url: this.urlTotalization,
        obj: this.itemsRent,
        notNotifyToast: true,
      };
      this.postApi(params).then((response) => {
        this.totalization = response.content.totalization;
        this.updateItemsRent(response.content.listItems);
        this.addTotalization(response.content.totalization);

        let self = this;
        setTimeout(function () {
          self.loadingCalculateTotalization = false;
        }, 300);
      });
    },
    validate() {
      let params = {
        url: this.urlValidate,
        obj: { itemsRent: this.itemsRent, periodRent: this.periodRent },
        notNotifyToast: true,
      };
      this.postApi(params).then((response) => {
        this.listError = response.content;
        if (this.listError.length > 0) this.openModal("listError");
        if (this.listError.length == 0) this.openModal("finalizeRent");
        this.loadingFinalize = false;
      });
    },
    openPeriodRent() {
      this.openModal("addPeriodRent");
      this.removeLoading(["btnAddPeriodRent"]);
    },
    editPeriodRent() {
      this.addEvent({
        name: "clickedPeriodRentItem",
        data: this.rent.periodRent,
      });
      this.openModal("periodRentUpdate");
      this.removeLoading(["btnEditPeriodRent"]);
    },
    cleanPeriodRent() {
      this.addEvent({ name: "cleanGeneratePeriodRent" });
      this.rent.periodRent = {};
      this.removeLoading(["btnCancelPeriodRent"]);
    },
    savePeriodRent() {
      this.addPeriodRent(this.rent.periodRent);
      this.hideModal("addPeriodRent");
      this.removeLoading(["savePeriodRent"]);
    },
    searchProduct() {
      this.openModal("searchProduct");
      this.removeLoading(["searchProduct"]);
    },
    finalizeRent() {
      this.loadingFinalize = true;
      this.validate();
    },
    changeCustomer() {
      this.rent.customer = "";
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "generatePeriodRent") {
          this.rent.periodRent = event.data;
        }

        if (
          event.name == "calculateTotalization" ||
          event.name == "updateIncrementDecrement"
        ) {
          this.calculateTotalization();
        }
      },
      deep: true,
    },
    rent: {
      handler() {
        this.updateRent(this.rent);
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.div-btn-end {
  margin-top: 5px;
}

.div-btn-detail {
  margin-top: 13px;
}

.btn-edit-cancel {
  margin-top: 14px;
  margin-bottom: 13px;
}

.div-collaborator {
  margin-left: 10px;
  margin-bottom: 10px;
}

.btn-finalize {
  padding: 8px 12px;
  cursor: pointer;
  border: none;
  width: 200px;
  border-radius: 40px !important;
  font-weight: normal;
  font-style: normal !important;
  font-size: 18px;
  letter-spacing: 1px !important;
  color: white;
  background: #94aa2a;
  border-color: #94aa2a;
  -webkit-box-shadow: 0px 10px 20px -6px rgb(0 0 0 / 12%);
  -moz-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 10px 20px -6px rgb(0 0 0 / 12%);
}

.btn-finalize:hover {
  background: #6d7e1c;
}

.btn-finalize-disabled {
  background: #bbbbbb !important;
  border-color: #bbbbbb !important;
  color: #fff;
}

.div-btn-period {
  padding-top: 20px;
}

.line {
  margin-bottom: 42px;
}

.div-tab {
  margin-top: 12px;
}
</style>
