export default class PdvRent {
    constructor() {
        this.id = "";
        this.identification = "";
        this.collaborator = { id: "", content: "" };
        this.customer = { id: "", content: "" };
        this.contact = { id: "", content: "" };
        this.periodRent = {};
        this.observationRental = "";
        this.observationServiceOrder = "";
    }
}