<template>
  <div>
    <div>
      <Button _key="btnItemsPdvCreateUpdate" title="Pesquisar Manual" classIcon="fa-regular fa-magnifying-glass"
        type="primary" size="small" :disabled="disabled" :clicked="create" />
    </div>
    <Modal title="Produtos" :width="1200" :height="750" v-if="showModal('addProduct')">
      <SearchProduct v-if="showSearchProduct" :types="'1,2,3,0,5'" :status="1" :statusPatrimony="1" :showPrice="true"
        :showAvailability="true" :onlyPatrimony="userLogged.parameterStock.manageByPatrimony" :periodRent="periodRent"
        :addProduct="add">
      </SearchProduct>
    </Modal>
  </div>
</template>

<script>
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import SearchProduct from "../../stock/search-product/SearchProduct.vue";

import { mapState, mapMutations, mapGetters, mapActions } from "vuex";

export default {
  name: "ItemsRentCreate",
  components: {
    InputText,
    Modal,
    Button,
    SearchProduct,
  },
  props: {
    periodRent: Object,
    disabled: Boolean,
  },
  data() {
    return {
      showSearchProduct: false,
      urlCreate: "/api/v1/pdv/add-product",
      urlVerifyAccessory: "/api/v1/pdv/verify-if-has-accessory",
    };
  },
  computed: {
    ...mapState("user", ["userLogged"]),
    ...mapGetters("generic", ["showModal", "event"]),
    ...mapGetters("pdv", ["verifyIfExistProduct"]),
  },
  methods: {
    ...mapMutations("generic", ["addEvent", "openModal", "removeLoading"]),
    ...mapMutations("pdv", ["addProduct"]),
    ...mapActions("generic", ["postApi"]),
    create() {
      this.openModal("addProduct");
      this.removeLoading(["btnItemsPdvCreateUpdate"]);
      this.showSearchProduct = true;
    },
    add(product) {
      if (this.verifyIfExistProduct(product)) {
        this.$toasted.show("Produto já existe", {
          type: "error",
        });
        this.hideLoading(product.id);
        return;
      }
      product.unitValue = product.value;
      let params = { url: this.urlCreate, obj: product, notNotifyToast: true };
      this.postApi(params).then((response) => {
        product.totalUnitValue = response.content.totalUnitValue;
        product.unitDiscount = response.content.unitDiscount;
        product.unitInsurance = response.content.unitInsurance;
        product.totalUnitDiscount = response.content.totalUnitDiscount;
        product.unitCost = response.content.unitCost;
        product.totalUnitCost = response.content.totalUnitCost;
        product.totalUnitInsurance = response.content.totalUnitInsurance;

        this.addProduct(product);
        this.hideLoading(product.id);
        this.VerifyIfHasAccessory(product.productId);
        this.$toasted.show("Produto adicionado", {
          type: "success",
        });
        this.addEvent({ name: "calculateTotalization" });
      });
    },
    VerifyIfHasAccessory(productId) {
      let params = {
        url: this.urlVerifyAccessory,
        obj: productId,
        notNotifyToast: true,
      };
      this.postApi(params).then((response) => {
        response.content.data.forEach((accessory) => {
          let product = {
            type: 2,
            typeName: "onlyAccessory",
            productFullName: accessory.fullName,
            productId: accessory.product.id,
            quantity: accessory.quantity,
            totalStock: accessory.totalStock,
            allowWithoutPatrimony: accessory.allowWithoutPatrimony,
            barCode: accessory.barCode,
            totalUnitValue: 0,
            unitDiscount: 0,
            unitInsurance: 0,
            totalUnitDiscount: 0,
            unitCost: 0,
            totalUnitCost: 0,
            totalUnitInsurance: 0,
          };
          this.addProduct(product);
        });
      });
    },
    hideSearchProduct() {
      this.showSearchProduct = false;
    },
    hideLoading(productId) {
      let self = this;
      setTimeout(function () {
        self.removeLoading([productId]);
      }, 300);
    },
  },
};
</script>
