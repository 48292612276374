<template>
  <div>
    <b-row>
      <b-col xs="12" sm="12" md="12" lg="12" xl="4">
        <div class="div-select">
          <Select title="Tipo de Pagamento" field="typePayment" :required="true" :markFormDirty="false"
            :propsParams="{ any: module }" v-model="payment.typePayment" url="/api/v1/finance/type-payment/select-all"
            :showNewRegister="hasRule('Rental')" nameNewRegister="typePaymentCreateUpdate"
            titleNewRegister="Tipo de Pagamento" :widthNewRegister="500" :heightNewRegister="250">
            <TypePaymentCreateUpdate :registerInSelect="true" v-model="payment.typePayment" />
          </Select>
        </div>
      </b-col>
      <b-col xs="12" sm="12" md="12" lg="12" xl="4" v-if="payment.typePayment.isBankSlip">
        <Select title="Conta Boleto" field="bankAccount" :required="true" :showNewRegister="false"
          url="api/v1/billing/billet-config/select-all" v-model="payment.bankAccount" />
      </b-col>
      <b-col xs="12" sm="12" md="12" lg="12" xl="3">
        <InputDecimal title="Valor" field="value" :formName="formName" :required="true" :markFormDirty="false"
          :maxLength="5" type="float" v-model="payment.value" />
      </b-col>
      <b-col xs="12" sm="12" md="12" lg="6" xl="2">
        <DateTime title="Vencimento" field="dueDate" format="DD/MM/YYYY" type="date" placeholder :formName="formName"
          :required="true" v-model="payment.dueDate" />
      </b-col>
      <b-col xs="12" sm="12" md="12" lg="12" xl="1">
        <div class="div-btn-add">
          <Button _key="btnAddPaymentRent" type="primary" :disabled="!isFormValid(formName)" classIcon="fa-solid fa-plus"
            size="small" :clicked="add" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import InputDecimal from "@nixweb/nixloc-ui/src/component/forms/InputDecimal";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import CheckboxGroup from "@nixweb/nixloc-ui/src/component/forms/CheckboxGroup";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";

import Payment from "@/components/modules/shared/payment-items/Payment.js";

import TypePaymentCreateUpdate from "../../finance/type-payment/TypePaymentCreateUpdate.vue";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "PaymentCreate",
  components: {
    InputText,
    InputDecimal,
    DateTime,
    Select,
    Button,
    CheckboxGroup,
    TypePaymentCreateUpdate,
    TypePaymentCreateUpdate,
  },
  props: {
    module: String,
    totalValue: Number,
  },
  data() {
    return {
      formName: "paymentCreateUpdate",
      payment: new Payment(),
    };
  },
  mounted() {
    this.payment.value = this.totalValue;
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
    ...mapGetters("user", ["hasRule"]),
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi"]),
    ...mapMutations("generic", ["removeLoading"]),
    ...mapMutations("validation", ["resetValidation"]),
    ...mapMutations("paymentShared", ["addPayment"]),
    add() {
      this.addPayment(this.payment);
      this.payment = new Payment();
      this.resetValidation(this.formName);
      this.removeLoading(["btnAddPaymentRent"]);
    },
    update(data) {
      this.payment = new Payment();
      this.payment.update(data);
      this.removeLoading([data.id]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "paymentRentUpdate") this.update(event.data);
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.div-btn-add {
  margin-top: 36px;
}



</style>