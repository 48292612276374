<template>
  <div class="main">
    <div>
      <Panel
        module="PDV"
        title="Locação"
        :showFilter="false"
        :showSearch="false"
        :showButtons="false"
      >
        <div slot="content-main">
          <br />
          <RentCreate />
        </div>
      </Panel>
    </div>
  </div>
</template>
<script>
import Panel from "@nixweb/nixloc-ui/src/component/layout/Panel";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import RentCreate from '../../../components/modules/pdv/rent/RentCreate.vue'

import { mapMutations } from "vuex";

export default {
  name: "PdvRentCreateUpdateView",
  components: {
    Panel,
    Button, RentCreate
  },
  mounted() {
    this.removeLoading(["panel", "btnPdvCreate"]);
  },
  methods: {
    ...mapMutations("generic", ["removeLoading"]),
  },
};
</script>

<style scoped>
.main {
  margin-left: 120px;
  margin-top: 100px;
  margin: auto;
}

.title {
  color: #252422;
  margin: 0;
  text-align: center;
  font-size: 18px;
}
</style>