<template>
  <div>
    <div>
      <SelectOption v-show="showSelectedOption" buttonSize="large" buttonClassIcon="fas fa-arrow-circle-right"
        :width="180" :height="190" :options="options" v-model="selectedOption" :clickedNext="clickedNext" />
    </div>
    <div v-if="!showSelectedOption && selectedOption == 1">
      <Alert type="warning" v-if="totalization.totalGeneral - sumPayment != 0">
        <span>
          Restante
          {{ difference | currency }}</span>
      </Alert>
      <Molded>
        <PaymentCreate module="PDV" :totalValue="totalization.totalGeneral" />
      </Molded>
      <PaymentsList />
    </div>
    <div class="div-btn text-center">
      <Button _key="btnBack" v-show="!showSelectedOption" type="info" title="voltar"
        classIcon="fa-solid fa-circle-arrow-left" size="small" :clicked="back" />
      <Button _key="btnGenerateRent" v-show="!showSelectedOption" type="success" title="Gerar Locação" size="large"
        :disabled="sumPayment != totalization.totalGeneral && selectedOption == 1
          " :clicked="generateRent" />
    </div>
  </div>
</template>

<script>
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button.vue";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import SelectOption from "@nixweb/nixloc-ui/src/component/shared/SelectOption.vue";

import PaymentsList from "../../shared/payment-items/PaymentsList.vue";
import PaymentCreate from "../../shared/payment-items/PaymentCreate.vue";

import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "FinalizeRent",
  components: {
    Molded,
    Button,
    Alert,
    SelectOption,
    PaymentCreate,
    PaymentsList,
  },
  data() {
    return {
      invoice: false,
      urlGenerate: "/api/v1/pdv/generate-rent",
      loading: false,
      selectedOption: "",
      showSelectedOption: true,
      options: [
        {
          title: "Receber",
          titleSize: "17",
          icon: "fas fa-dollar-sign",
          iconSize: 40,
          value: 1,
        },
        {
          title: "A Faturar",
          titleSize: "17",
          icon: "fas fa-file-invoice-dollar",
          iconSize: 40,
          value: 2,
        },
      ],
    };
  },
  computed: {
    ...mapState("user", ["userLogged"]),
    ...mapGetters("paymentShared", ["sumPayment"]),
    ...mapState("paymentShared", ["paymentsRent"]),
    ...mapState("pdv", [
      "cashierId",
      "identification",
      "collaboratorId",
      "customerId",
      "contactId",
      "periodRent",
      "itemsRent",
      "addressRent",
      "observationRental",
      "observationServiceOrder",
      "totalization",
      "isInvoice",
    ]),
    difference() {
      return (
        this.totalization.totalGeneral.toFixed(2) - this.sumPayment.toFixed(2)
      );
    },
  },
  methods: {
    ...mapActions("generic", ["postApi"]),
    ...mapMutations("generic", ["hideModal", "removeLoading"]),
    ...mapMutations("pdv", ["updateIsInvoice"]),
    clickedNext() {
      this.showSelectedOption = false;
      if (this.selectedOption == 1) this.updateIsInvoice(false);
      if (this.selectedOption == 2) this.updateIsInvoice(true);
    },
    back() {
      this.showSelectedOption = true;
      this.removeLoading(["btnBack"]);
    },
    generateRent() {
      let params = {
        url: this.urlGenerate,
        obj: {
          cashierId: this.cashierId,
          identification: this.identification,
          collaboratorId: this.collaboratorId,
          customerId: this.customerId,
          contactId: this.contactId,
          periodRent: this.periodRent,
          itemsRent: this.itemsRent,
          paymentsRent: this.paymentsRent,
          addressRent: this.addressRent,
          observationRental: this.observationRental,
          observationServiceOrder: this.observationServiceOrder,
          isInvoice: this.isInvoice,
        },
      };
      this.postApi(params).then((response) => {
        if (response.success) {
          this.$router.push({
            name: "successPdvRent",
            params: { rentId: response.content.content.id },
          });
          this.hideModal();
        }
        this.removeLoading(["btnGenerateRent"]);
      });
    },
  },
};
</script>
<style scoped>
.div-btn {
  margin-top: 20px;
}
</style>
