<template>
  <div>
    <ScrollBar :minHeight="330" :maxHeight="330">
      <div>
        <br />
        <table class="table table-responsive-xs">
          <thead>
            <tr>
              <th class="title-header text-center">Seq</th>
              <th class="title-header">Produto</th>
              <th class="title-header text-center">Código/Patrimônio</th>
              <th class="title-header text-center">Qtd</th>
              <th class="title-header text-center">Valor</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody v-for="itemRent in itemsRent" :key="itemRent.seq">
            <tr>
              <td class="text-center">
                <span class="seq"> {{ itemRent.seq }}</span>
              </td>
              <td class="editavel tabela-principal table-product" @click="updateProduct(itemRent)">
                <span> {{ itemRent.productFullName }}</span><br />
                <span class="descricao">{{ itemRent.description }}</span>
              </td>
              <td class="tabela-principal tabela-descricao text-center">
                <InputCallToAction v-if="!itemRent.allowWithoutPatrimony" :_key="itemRent.id"
                  urlCallToAction="/api/v1/stock/patrimony/get-by-barcode" :params="{ any: itemRent.productId }"
                  placeholder="Informe o patrimônio" messageError="Não disponível ou não existe..."
                  v-model="itemRent.barCode" />
              </td>
              <td class="table-quantity text-center">
                <span v-if="!itemRent.allowWithoutPatrimony && manageByPatrimony">
                  {{ itemRent.quantity }}
                </span>
                <IncrementDecrement v-else :triggerEvent="true" :min="1" v-model="itemRent.quantity" />
              </td>
              <td class="tabela-principal text-center">
                <ItemsRentalTotalization v-if="itemRent.typeName != 'onlyAccessory'" :data="{
      unitValue: itemRent.unitValue,
      totalUnitValue: itemRent.unitValue * itemRent.quantity,
      unitDiscount: itemRent.unitDiscount,
      totalUnitDiscount:
        itemRent.unitDiscount * itemRent.quantity,
      unitCost: itemRent.unitCost,
      totalUnitCost: itemRent.totalUnitCost,
      totalUnitInsurance:
        itemRent.unitInsurance * itemRent.quantity,
    }" />
              </td>
              <td class="text-center">
                <div class="icon-product">
                  <span v-if="itemRent.type == 1">
                    <i class="fas fa-box"></i>
                  </span>
                  <span v-if="itemRent.type == 2">
                    <i class="fas fa-toolbox"></i>
                  </span>
                  <span v-if="itemRent.type == 3">
                    <i class="fas fa-people-carry"></i>
                  </span>
                </div>
              </td>
              <td @click="remove(itemRent.seq)">
                <span class="icone">
                  <i class="fas fa-times-circle"></i>
                </span>
              </td>
            </tr>
            <tr v-show="seq == itemRent.seq">
              <td colspan="7">
                <ItemsRentUpdate :itemRent="itemRent" :canUpdate="canUpdate" />
              </td>
            </tr>
          </tbody>
          <tbody v-show="itemsRent.length == 0">
            <tr>
              <td colspan="12">
                <span>Nenhum produto adicionado!</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ScrollBar>
  </div>
</template>

<script>
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import IncrementDecrement from "@nixweb/nixloc-ui/src/component/forms/IncrementDecrement.vue";
import InputCallToAction from "@nixweb/nixloc-ui/src/component/forms/InputCallToAction.vue";

import ItemsRentUpdate from "./ItemsRentUpdate.vue";
import ItemsRentalTotalization from "../../rental/items-rent/ItemsRentalTotalization.vue";

import { mapState, mapMutations, mapGetters } from "vuex";

export default {
  components: {
    ScrollBar,
    IncrementDecrement,
    InputCallToAction,
    ItemsRentalTotalization,
    ItemsRentUpdate,
  },
  name: "ItemsRentList",
  data() {
    return {
      seq: 0,
    };
  },
  computed: {
    ...mapState("pdv", ["itemsRent"]),
    ...mapState("user", ["userLogged"]),
    ...mapGetters("generic", ["event"]),
    ...mapGetters("user", ["hasPermission"]),
    manageByPatrimony() {
      return this.userLogged.parameterStock.manageByPatrimony;
    },
    canUpdate() {
      let hasPermission = this.hasPermission("PDV");
      if (hasPermission.includes("update")) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapMutations("generic", ["addEvent", "removeLoading"]),
    ...mapMutations("pdv", ["removeProduct"]),
    updateProduct(itemRent) {
      this.seq = itemRent.seq;
      this.itemRent.discount = {
        discountByPercentage: false,
        discount: this.itemRent.unitDiscount,
      };
    },
    remove(seq) {
      let self = this;
      setTimeout(function () {
        self.seq = 0;
      }, 300);
      this.removeProduct(seq);
      this.addEvent({ name: "calculateTotalization" });
      this.$toasted.show("Produto removido", {
        type: "success",
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (
          event.name == "updateProductPdv" ||
          event.name == "itemRentCancelUpdate"
        ) {
          this.seq = 0;
          this.removeLoading(["updateProductPdv", "cancelProductPdv"]);
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.table th,
.table td {
  height: 10px !important;
  padding-left: 5px !important;
  padding-top: 7px !important;
  padding-bottom: 5px !important;
  padding-right: 5px !important;
  padding-left: 10px !important;
  border-bottom: 0px !important;
}

.title-header {
  font-size: 14px;
  color: #757d8c;
  font-weight: 400;
  text-transform: uppercase;
}

.table-quantity {
  width: 120px;
}

.tabela-principal {
  font-size: 14px;
  max-width: 200px;
}

.tabela-descricao {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.table-product {
  min-width: 300px;
}

.descricao {
  font-size: 13px;
  color: darkgray;
}

.seq {
  margin-right: 5px;
  font-size: 15px;
}

.icon-product {
  margin-top: 5px;
}

.icone {
  font-size: 20px;
  color: #f0134d;
  cursor: pointer;
}

.icone:hover {
  color: #c70f40;
}

.editavel {
  cursor: pointer;
  background-color: #f1f4f9;
}
</style>
