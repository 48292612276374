<template>
  <div>
    <span class="unit value"><small>Unit</small> {{ data.unitValue | currency }}</span> |
    <span class="total value"><small>Tot..</small> {{ data.unitValue * data.quantity | currency }}</span>
    <br />
    <div class="badge badge-danger side-by-side" v-if="false">
      Desc {{ data.totalUnitDiscount | currency }}
    </div>
    <div v-if="data.unitDiscount.toFixed(2) > 0" class="side-by-side">
      <span class="unit value discount"><small>Desc</small> {{ data.totalUnitDiscount | currency }}</span> |
      <span class="total general value "><small>Tot..</small> {{ data.totalUnitValue | currency }}</span>
    </div>
    <div class="text-center">
      <div class="div-discount" v-if="data.percentageDiscount > 0">
        {{ data.percentageDiscount.toFixed(1) }}%
      </div>
    </div>
    <div>
      <div class="badge badge-danger side-by-side" v-if="data.unitCost > 0">
        Custo {{ data.totalUnitCost | currency }}
      </div>
      <div class="badge badge-info side-by-side" v-if="data.totalUnitInsurance > 0">
        Seguro {{ data.totalUnitInsurance | currency }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ItemsRentalTotalization",
  props: {
    data: Object,
  },
};
</script>
<style scoped>
.badge {
  font-size: 12px !important;
  margin-left: 5px;
}

.unit {
  font-size: 13px;
}

.total {
  font-weight: 600;
  font-size: 15px;
}

.discount {
  font-size: 13px !important;
  color: red;
}

.general {
  color: darkblue;
}

.value {
  font-family: "inter", sans-serif !important;
}

.div-discount {
  margin-left: 22%;
  margin-right: 66%;
  color: white;
  border-radius: 10px;
  background-color: #FF0201;
  font-size: 9px;
  opacity: 0.8;
}
</style>
