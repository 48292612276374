<template>
  <div>
    <b-row>
      <b-col sm="3">
        <InputDecimal title="Valor" field="value" :formName="formName" :required="true" :markFormDirty="false"
          :maxLength="5" type="float" v-model="paymentLocal.value" />
      </b-col>
      <b-col sm="3">
        <DateTime title="Vencimento" field="dueDate" format="DD/MM/YYYY" type="date" placeholder :formName="formName"
          :required="true" v-model="paymentLocal.dueDate" />
      </b-col>
      <b-col md="2">
        <div class="div-btn text-center">
          <Button _key="btnUpdatePayment" type="success" :disabled="!isFormValid(formName)" classIcon="fas fa-save"
            size="small" :clicked="update" />
          <Button _key="btnCancelPayment" type="danger" classIcon="fas fa-ban" size="small" :clicked="cancel" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputDecimal from "@nixweb/nixloc-ui/src/component/forms/InputDecimal";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapMutations, mapGetters } from "vuex";

export default {
  name: "PaymentRentUpdate",
  components: {
    InputDecimal,
    DateTime,
    Button,
  },
  props: {
    payment: Object,
  },
  data() {
    return {
      formName: "paymentRentUpdate",
      paymentLocal: {},
    };
  },
  mounted() {
    this.paymentLocal = JSON.parse(JSON.stringify(this.payment)); // remove observable
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
  },
  methods: {
    ...mapMutations("generic", ["addEvent"]),
    ...mapMutations("paymentShared", ["updatePayment"]),
    update() {
      this.updatePayment(this.paymentLocal);
      this.addEvent({ name: "paymentRentCancelUpdate" });
    },
    cancel() {
      this.addEvent({ name: "paymentRentCancelUpdate" });
    },
  },
};
</script>
<style scoped>
.div-btn {
  padding-top: 38px;
}
</style>