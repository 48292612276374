<template>
  <div>
    <ScrollBar :minHeight="330" :maxHeight="330">
      <div>
        <br />
        <table class="table table-responsive-xs">
          <thead>
            <tr>
              <th class="title-header">Descrição</th>
              <th class="title-header">Endereço</th>
              <th></th>
            </tr>
          </thead>
          <tbody v-for="address in addressRent">
            <tr>
              <td class="tabela-principal tabela-descricao">
                <span> {{ address.description }}</span>
              </td>
              <td class="tabela-principal tabela-descricao">
                <span> {{ address.address }}</span>
              </td>
              <td class="text-center" @click="remove(address.seq)">
                <span class="icone">
                  <i class="fas fa-times-circle"></i>
                </span>
              </td>
            </tr>
          </tbody>
          <tbody v-show="addressRent.length == 0">
            <tr>
              <td colspan="12">
                <span>Nenhum endereço adicionado!</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ScrollBar>
  </div>
</template>

<script>
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";

import { mapState, mapMutations, mapGetters } from "vuex";

export default {
  components: {
    ScrollBar,
  },
  name: "AddressRentList",
  data() {
    return {
      seq: 0,
    };
  },
  computed: {
    ...mapState("pdv", ["addressRent"]),
  },
  methods: {
    ...mapMutations("generic", ["addEvent", "removeLoading"]),
    ...mapMutations("pdv", ["removeAddress"]),
    remove(seq) {
      let self = this;
      setTimeout(function () {
        self.seq = 0;
      }, 300);
      this.removeAddress(seq);
      this.$toasted.show("Endereço removido", {
        type: "success",
      });
    },
  },
};
</script>
<style scoped>
.table th,
.table td {
  height: 10px !important;
  padding-left: 5px !important;
  padding-top: 7px !important;
  padding-bottom: 5px !important;
  padding-right: 5px !important;
  padding-left: 10px !important;
  border-bottom: 0px !important;
}

.title-header {
  font-size: 14px;
  color: #757d8c;
  font-weight: 400;
  text-transform: uppercase;
}

.table-quantity {
  width: 120px;
}

.tabela-principal {
  font-size: 14px;
  max-width: 200px;
}

.tabela-descricao {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.descricao {
  font-size: 13px;
  color: darkgray;
}

.seq {
  margin-right: 5px;
  font-size: 15px;
}

.icone {
  font-size: 20px;
  color: #f0134d;
  cursor: pointer;
}

.icone:hover {
  color: #c70f40;
}

.editavel {
  cursor: pointer;
  background-color: #f1f4f9;
}
</style>
