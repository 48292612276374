<template>
  <div>
    <b-row>
      <b-col sm="6">
        <InputText title="Nome" field="name" :formName="formName" :required="true" :maxLength="100"
          v-model="collaborator.personRequest.companyName" :markFormDirty="false" />
      </b-col>
      <b-col sm="6">
        <InputText title="Apelido" field="apelido" :formName="formName" :required="true" :maxLength="100"
          v-model="collaborator.personRequest.tradeName" :markFormDirty="false" />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <Select title="Usuário Vinculado" field="user" :formName="formName" :required="true"
          url="/api/v1/adm/user/select-all" v-model="collaborator.user" :markFormDirty="false">
        </Select>
      </b-col>
    </b-row>
    <br />
    <b-row>
      <b-col sm="12">
        <div class="text-right">
          <Button _key="btnSaveCollaborator" type="success" title="Salvar" :disabled="!isFormValid(formName)"
            classIcon="fas fa-save" size="medium" :clicked="saveCollaborator" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import Collaborator from "@/components/modules/human-resources/collaborator/Collaborator.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "ConsultantCreateUpdate",
  props: {
    registerInSelect: Boolean,
    value: Object,
  },
  components: { InputText, Select, Button },
  data() {
    return {
      formName: "consultantCreateUpdate",
      collaborator: new Collaborator(),
      urlCreate: "/api/v1/human-resources/collaborator/create",
    };
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi"]),
    ...mapMutations("generic", [
      "removeLoading",
      "removeEvent",
      "hideModal",
      "hideVodal",
    ]),
    ...mapMutations("validation", ["resetValidation"]),
    saveCollaborator() {
      this.collaborator.options = ["isConsultant"];
      this.collaborator.addressValueObject.state = "";
      let params = { url: this.urlCreate, obj: this.collaborator };
      this.postApi(params).then((response) => {
        if (response.success && !this.registerInSelect) this.hideModal();
        if (response.success && this.registerInSelect) {
          this.$emit("input", {
            id: response.content.id,
            content: `${response.content.person.companyName} (${response.content.person.tradeName})`,
          });
          this.hideVodal(this.formName);;
        }
        this.removeLoading(["btnSaveCollaborator"]);
      });
    },
  },
};
</script>
