<template>
  <div>
    <b-row>
      <b-col md="4">
        <InputText
          title="Descrição"
          field="description"
          :formName="formName"
          :required="false"
          :maxLength="400"
          v-model="itemRentLocal.description"
          :enter="update"
        />
      </b-col>
      <b-col md="2" v-if="itemRentLocal.typeName != 'onlyAccessory'">
        <InputDecimal
          title="Valor Unitário"
          field="value"
          :formName="formName"
          :required="true"
          :disabled="!canUpdate"
          v-model="itemRentLocal.unitValue"
          :enter="update"
        />
      </b-col>
      <b-col md="2" v-if="itemRentLocal.typeName != 'onlyAccessory'">
        <InputDecimalDiscount
          title="Desconto Unitário"
          field="unitDiscount"
          :formName="formName"
          :required="false"
          _style="color:red;"
          :maxLength="5"
          type="float"
          v-model="itemRentLocal.discount"
        />
      </b-col>
      <b-col sm="2" v-if="itemRentLocal.typeName != 'onlyAccessory'">
        <InputDecimal
          title="Seguro Unitário"
          field="unitInsurance"
          :formName="formName"
          :required="false"
          :markFormDirty="false"
          :disabled="!canUpdate"
          :maxLength="5"
          type="float"
          v-model="itemRentLocal.unitInsurance"
        />
      </b-col>
      <b-col md="2">
        <div class="div-btn text-center">
          <Button
            _key="updateProductPdv"
            type="success"
            :disabled="!isFormValid(formName)"
            classIcon="fas fa-save"
            size="small"
            :clicked="update"
          />
          <Button
            _key="cancelProductPdv"
            type="danger"
            classIcon="fas fa-ban"
            size="small"
            :clicked="cancel"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import Button from "@nixweb/nixloc-ui/src/component/forms/Button.vue";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import InputNumber from "@nixweb/nixloc-ui/src/component/forms/InputNumber";
import InputDecimal from "@nixweb/nixloc-ui/src/component/forms/InputDecimal";
import InputDecimalDiscount from "@nixweb/nixloc-ui/src/component/forms/InputDecimalDiscount";

import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  name: "ItemsRentUpdate",
  props: {
    itemRent: Object,
    canUpdate: Boolean,
  },
  components: {
    Button,
    InputText,
    InputNumber,
    InputDecimal,
    InputDecimalDiscount,
  },
  data() {
    return {
      formName: "productUpdate",
      itemRentLocal: {},
    };
  },
  mounted() {
    this.itemRentLocal = JSON.parse(JSON.stringify(this.itemRent)); // remove observable
  },
  computed: {
    ...mapState("user", ["userLogged"]),
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapMutations("generic", ["addEvent"]),
    ...mapMutations("pdv", ["updateProduct"]),
    update() {
      this.updateProduct(this.itemRentLocal);
      this.addEvent({ name: "itemRentCancelUpdate" });
      this.addEvent({ name: "calculateTotalization" });
    },
    cancel() {
      this.addEvent({ name: "itemRentCancelUpdate" });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "pdvProductUpdate")
          this.pdvProductUpdate.update(event.data);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.div-btn {
  padding-top: 38px;
}
</style>
