<template>
  <div>
    <div v-if="module == 'rent'">
      <Select title="Consultor" field="type" :formName="formName" :required="true" :disabled="disabled"
        url="/api/v1/human-resources/collaborator/select-all" :propsParams="{ isConsultant: true }" v-model="collaborator"
        nameNewRegister="consultantCreateUpdate" :showNewRegister="hasRule('HumanResources')" titleNewRegister="Consultor"
        :widthNewRegister="800" :heightNewRegister="250">
        <ConsultantCreateUpdate :registerInSelect="true" v-model="collaborator" />
      </Select>
    </div>
    <div v-else>
      <div class="side-by-side div-icon">
        <i class="fa-duotone fa-user"></i>
      </div>
      <div class="side-by-side div-collaborator">
        {{ collaborator.content }}
      </div>
    </div>
  </div>
</template>

<script>
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import ConsultantCreateUpdate from "../../human-resources/collaborator/ConsultantCreateUpdate.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "CollaboratorWithUser",
  components: {
    Select,
    ConsultantCreateUpdate,
  },
  props: ["formName", "disabled", "module", "value"],
  data() {
    return {
      collaborator: { id: "", content: "" },
      urlGetUser: "/api/v1/human-resources/collaborator/get-by-user",
    };
  },
  mounted() {
    this.getByUser();
  },
  computed: {
    ...mapGetters("user", ["hasRule"]),
  },
  methods: {
    ...mapActions("generic", ["getApi"]),
    getByUser() {
      let params = { url: this.urlGetUser, obj: {} };
      this.getApi(params).then((response) => {
        this.collaborator = response.content;
      });
    },
  },
  watch: {
    collaborator: {
      handler(collaborator) {
        this.$emit("input", {
          id: collaborator.id,
          content: collaborator.name,
        });
      },
      deep: true,
    },
    value: {
      handler(value) {

      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.div-icon {
  font-size: 18px;
  margin-right: 15px;
}

.div-collaborator {
  font-size: 16px;
}
</style>